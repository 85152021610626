'use client';

import { enUS, frFR } from '@clerk/localizations';
import { ClerkProvider } from '@clerk/nextjs';
import type { PropsWithChildren } from 'react';

import { ROUTES } from '@/hooks/use-routes';

type IProps = PropsWithChildren & { params: { locale: string } };

export const ClerkClientProvider = ({ children, params }: IProps) => {
  let clerkLocale = enUS;
  let explorerUrl = ROUTES.AUTH_HOME;

  if (params.locale === 'fr') {
    clerkLocale = frFR;
  }

  if (params.locale !== 'en') {
    explorerUrl = `/${params.locale}${explorerUrl}`;
  }

  // const theme = useTheme();

  return (
    <ClerkProvider
      localization={clerkLocale}
      signInFallbackRedirectUrl={explorerUrl}
      signUpFallbackRedirectUrl={explorerUrl}
      // afterSignOutUrl="/"
      appearance={{
        baseTheme: undefined,
        // TODO: decide if we want dark mode, some buttons are not the right colour
        // baseTheme: theme.resolvedTheme === 'dark' ? dark : undefined,
      }}
    >
      {children}
    </ClerkProvider>
  );
};
