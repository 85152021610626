import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/node_modules/nextjs-toploader/dist/index.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/src/components/cookie-consent/cookie-consent-manager.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/src/components/theme-provider.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/src/components/ui/hybrid-tooltip.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/src/components/ui/sonner.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/src/components/ui/tooltip.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/src/providers/clerk-client.provider.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/src/providers/file-manager.provider.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/src/providers/query-client.provider.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/src/styles/global.css")