import { useQuery } from '@tanstack/react-query';

import type {
  KnowledgeBaseFileLocation,
  KnowledgeBaseFolderId,
  KnowledgeBaseFolderLocation,
  KnowledgeBaseId,
} from '@/data/commons';
import {
  type KnowledgeBaseFileEntity,
  type KnowledgeBaseFolderEntity,
  KnowledgeBaseService,
} from '@/generated/clients/ludus-service-client/requests';
import { pageThrough } from '@/utils/pagination.utils';

export type IUseListKnowledgeBaseFolderProps = {
  kbId: KnowledgeBaseId;
  folderId: KnowledgeBaseFolderId;
  enabled?: boolean;
  limit?: number;
  fileLocation?: KnowledgeBaseFileLocation;
  folderLocation?: KnowledgeBaseFolderLocation;
};

const filesAsyncGenerator = (
  kbId: string,
  parentFolderId: string,
  limit: number,
  location?: KnowledgeBaseFileLocation,
) =>
  pageThrough(limit, async (filesLimit, nextCursor) => {
    const filesResponse = await KnowledgeBaseService.findKnowledgeBaseFiles({
      kbId,
      parentFolderId,
      includeUploader: true,
      location,
      limit: filesLimit,
      nextCursor,
    });

    return filesResponse;
  });

const foldersAsyncGenerator = (
  kbId: string,
  parentFolderId: string,
  limit: number,
  location?: KnowledgeBaseFileLocation,
) =>
  pageThrough(limit, async (foldersLimit, nextCursor) => {
    const foldersResponse = await KnowledgeBaseService.findKnowledgeBaseFolders(
      {
        kbId,
        parentFolderId,
        includeUploader: true,
        location,
        limit: foldersLimit,
        nextCursor,
      },
    );

    return foldersResponse;
  });

interface IFilesAndFolders {
  files: KnowledgeBaseFileEntity[];
  folders: KnowledgeBaseFolderEntity[];
}

export const getAllFilesAndFolders = async (
  kbId: string,
  parentFolderId: string,
  batchSize: number,
  fileLocation?: KnowledgeBaseFileLocation,
  folderLocation?: KnowledgeBaseFolderLocation,
): Promise<IFilesAndFolders> => {
  const asyncFolders = foldersAsyncGenerator(
    kbId,
    parentFolderId,
    batchSize,
    folderLocation,
  );

  const asyncFiles = filesAsyncGenerator(
    kbId,
    parentFolderId,
    batchSize,
    fileLocation,
  );

  let files: KnowledgeBaseFileEntity[] = [];
  let folders: KnowledgeBaseFolderEntity[] = [];

  const getFilesAndFoldersPromises: (() => Promise<IFilesAndFolders>)[] = [];

  for await (const folder of asyncFolders) {
    getFilesAndFoldersPromises.push(() => {
      return getAllFilesAndFolders(
        kbId,
        folder.id,
        batchSize,
        fileLocation,
        folderLocation,
      );
    });

    folders.push(folder);
  }

  for await (const file of asyncFiles) {
    files.push(file);
  }

  const allFilesAndFolders = await Promise.all(
    getFilesAndFoldersPromises.map((fn) => fn()),
  );

  allFilesAndFolders.forEach((filesAndFolders) => {
    files = [...files, ...filesAndFolders.files];
    folders = [...folders, ...filesAndFolders.folders];
  });

  return { files, folders };
};

export function useListKnowledgeBaseFolder({
  kbId,
  folderId,
  enabled,
  limit: batchSize,
  fileLocation,
  folderLocation,
}: IUseListKnowledgeBaseFolderProps) {
  const query = useQuery({
    enabled,
    queryKey: [
      'useListKnowledgeBaseFolder',
      kbId,
      folderId,
      fileLocation,
      folderLocation,
      batchSize,
    ],
    queryFn: async () => {
      const filesAndFolders = await getAllFilesAndFolders(
        kbId,
        folderId,
        batchSize ?? 1000,
        fileLocation,
        folderLocation,
      );

      return filesAndFolders;
    },
  });

  const { data } = query;

  return { ...query, filesAndFolders: data };
}
