'use client';

import 'vanilla-cookieconsent/dist/cookieconsent.css';

import React, { useEffect } from 'react';
import * as VanillaCookieConsent from 'vanilla-cookieconsent';

export interface ICookieConsentProps {
  onConsent?: (param: { cookie: VanillaCookieConsent.CookieValue }) => void;
}

export const CookieConsent = React.memo((props: ICookieConsentProps) => {
  useEffect(() => {
    VanillaCookieConsent.run({
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        analytics: {},
      },

      onConsent: props.onConsent,

      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'This Website Uses Cookies',
              description:
                'This site uses cookies to enhance your browsing experience and help us improve performance.',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage Individual preferences',
            },
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  description:
                    'We use cookies to optimize our site functionality and understand visitor behavior. Manage your cookie settings to tailor your experience.',
                },
                {
                  title: 'Strictly Necessary cookies',
                  description: `These cookies are essential for the basic functionality of our website. Without them, core features like security, page navigation, and access to certain areas of the site wouldn't work properly. They cannot be turned off, as they are necessary for ensuring the website operates correctly.`,

                  // this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Performance and Analytics',
                  description: `These cookies help us improve the site by gathering data on how it's used. The information is anonymized and helps us enhance performance and user experience.`,
                  linkedCategory: 'analytics',
                },
              ],
            },
          },
        },
      },
    });
  });

  return <div className="hidden" />;
});

CookieConsent.displayName = 'CookieConsent';
