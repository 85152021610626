'use client';

import { AxiosHeaders, type AxiosRequestConfig } from 'axios';

import { OpenAPI as OpenAPIConfig } from '@/generated/clients/ludus-service-client/requests';
import { Interceptors } from '@/generated/clients/ludus-service-client/requests/core/OpenAPI';
import { wait } from '@/utils/async.utils';

let initialized = false;

export const configureOpenAPI = () => {
  if (initialized) {
    return;
  }

  const requestInterceptor = new Interceptors<AxiosRequestConfig>();

  requestInterceptor.use(async (config) => {
    let clerk = (window as any)?.Clerk?.session;

    let retryCount = 0;

    while (!clerk && retryCount <= 5) {
      retryCount += 1;
      // eslint-disable-next-line no-await-in-loop
      await wait(500);

      if ((window as any).Clerk) {
        clerk = (window as any).Clerk.session;
      }
    }

    const token = await clerk.getToken();

    if (token && config && config.headers) {
      if (config.headers instanceof AxiosHeaders) {
        config.headers.set('Authorization', `Bearer ${token}`);
      } else {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  });

  OpenAPIConfig.interceptors.request = requestInterceptor;

  initialized = true;
};
