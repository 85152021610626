import _ from 'lodash';

export const wait = async (ms: number) => {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
};

interface IBatchPromiseAllArgs<TItem, SOutput> {
  items: TItem[];
  batchSize: number;
  task: (item: TItem) => Promise<SOutput>;
}

export const batchPromiseAll = async <TItem, SOutput = undefined>({
  items,
  batchSize,
  task,
}: IBatchPromiseAllArgs<TItem, SOutput>) => {
  let results: SOutput[] = [];

  const itemBatches = _.chunk(items, batchSize);

  for (const itemBatch of itemBatches) {
    // eslint-disable-next-line no-await-in-loop
    const batchResults = await Promise.all(itemBatch.map((item) => task(item)));

    results = [...results, ...batchResults];
  }

  return results;
};
