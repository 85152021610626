/* eslint-disable max-classes-per-file */
/* eslint-disable no-await-in-loop */

'use client';

import { Trash2, Upload } from 'lucide-react';
import { type PropsWithChildren } from 'react';
import type { FileWithPath } from 'react-dropzone';

import type { ExternalFileWithPath } from '@/api/types';
import type { KnowledgeBaseFolderId, KnowledgeBaseId } from '@/data/commons';
import {
  type CreateKnowledgeBaseFileUploadResponse,
  type KnowledgeBaseFileEntity,
  type KnowledgeBaseFolderEntity,
} from '@/generated/clients/ludus-service-client/requests';

export type IFileManagerProviderProps = PropsWithChildren;

export type FileManagerTaskType =
  | 'UPLOAD_FILE'
  | 'DELETE_FILE'
  | 'DELETE_FOLDER';

export type FileManagerTaskStatus =
  | 'STARTED'
  | 'IN_PROGRESS'
  | 'FAILED'
  | 'SUCCESS';

export abstract class IFileManagerTask {
  public readonly id: string;
  public readonly progress: number;
  public readonly status: FileManagerTaskStatus;
  public readonly startDate: Date;
  public readonly type: FileManagerTaskType;
  public readonly err?: string;

  constructor(init: {
    id: string;
    progress: number;
    status: FileManagerTaskStatus;
    startDate: Date;
    type: FileManagerTaskType;
    err?: string;
  }) {
    this.id = init.id;
    this.progress = init.progress;
    this.status = init.status;
    this.startDate = init.startDate;
    this.type = init.type;
    this.err = init.err;
  }

  abstract getTaskText(): string | React.ReactNode;
}

export class UploadFileTask extends IFileManagerTask {
  public readonly fileName: string;
  public readonly fileSize: number;
  public readonly folders: string[];

  constructor(init: {
    id: string;
    progress: number;
    status: FileManagerTaskStatus;
    startDate: Date;
    fileName: string;
    fileSize: number;
    folders: string[];
    err?: string;
  }) {
    super({
      id: init.id,
      progress: init.progress,
      status: init.status,
      startDate: init.startDate,
      type: 'UPLOAD_FILE',
      err: init.err,
    });

    this.fileName = init.fileName;
    this.fileSize = init.fileSize;
    this.folders = init.folders;
  }

  getTaskText(): string | React.ReactNode {
    return (
      <div className="flex flex-row">
        <Upload className="mr-2 size-4 shrink-0 whitespace-nowrap" />
        {this.fileName}
      </div>
    );

    // if (this.status === 'STARTED') {
    //   return `Begin uploading ${this.fileName}`;
    // }
    // if (this.status === 'IN_PROGRESS') {
    //   return `Uploading ${this.fileName}`;
    // }
    // if (this.status === 'SUCCESS') {
    //   return `Successfully uploaded ${this.fileName}`;
    // }
    // if (this.status === 'FAILED') {
    //   return `Failed to upload ${this.fileName}`;
    // }

    // return `Uploading ${this.fileName}`;
  }
}

export class DeleteFileTask extends IFileManagerTask {
  public readonly fileId: string;
  public readonly fileName: string;

  constructor(init: {
    id: string;
    progress: number;
    status: FileManagerTaskStatus;
    startDate: Date;
    fileId: string;
    fileName: string;
    err?: string;
  }) {
    super({
      id: init.id,
      progress: init.progress,
      status: init.status,
      startDate: init.startDate,
      type: 'DELETE_FILE',
      err: init.err,
    });

    this.fileId = init.fileId;
    this.fileName = init.fileName;
  }

  getTaskText(): string | React.ReactNode {
    return (
      <div className="flex flex-row">
        <Trash2 className="mr-2 size-4 shrink-0 whitespace-nowrap" />
        {this.fileName}
      </div>
    );
    // if (this.status === 'STARTED') {
    //   return `Begin deleting ${this.fileName}`;
    // }
    // if (this.status === 'IN_PROGRESS') {
    //   return `Deleting ${this.fileName}`;
    // }
    // if (this.status === 'SUCCESS') {
    //   return `Successfully deleted ${this.fileName}`;
    // }
    // if (this.status === 'FAILED') {
    //   return `Failed to delete ${this.fileName}`;
    // }

    // return `Deleting ${this.fileName}`;
  }
}

export class DeleteFolderTask extends IFileManagerTask {
  public readonly folderId: string;
  public readonly folderName: string;

  constructor(init: {
    id: string;
    progress: number;
    status: FileManagerTaskStatus;
    startDate: Date;
    folderId: string;
    folderName: string;
    err?: string;
  }) {
    super({
      id: init.id,
      progress: init.progress,
      status: init.status,
      startDate: init.startDate,
      type: 'DELETE_FOLDER',
      err: init.err,
    });

    this.folderId = init.folderId;
    this.folderName = init.folderName;
  }

  getTaskText(): string | React.ReactNode {
    return (
      <div className="flex flex-row">
        <Trash2 className="mr-2 size-4 shrink-0 whitespace-nowrap" />
        {this.folderName}
      </div>
    );
    // if (this.status === 'STARTED') {
    //   return `Begin deleting ${this.folderName}`;
    // }
    // if (this.status === 'IN_PROGRESS') {
    //   return `Deleting ${this.folderName}`;
    // }
    // if (this.status === 'SUCCESS') {
    //   return `Successfully deleted ${this.folderName}`;
    // }
    // if (this.status === 'FAILED') {
    //   return `Failed to delete ${this.folderName}`;
    // }

    // return `Deleting ${this.folderName}`;
  }
}

export type IHandleFileUploaderFactoryProps = {
  onUploadBegin?: (
    kbId: KnowledgeBaseId,
    folderId: KnowledgeBaseFolderId,
    file: File,
  ) => void;
  onUploadError?: (
    kbId: KnowledgeBaseId,
    folderId: KnowledgeBaseFolderId,
    file: File,
    err?: any,
  ) => void;
  onUploadSuccess?: (
    kbId: KnowledgeBaseId,
    folderId: KnowledgeBaseFolderId,
    file: File,
    kbFile: KnowledgeBaseFileEntity,
  ) => void;
  onUploadProgress?: (
    kbId: KnowledgeBaseId,
    folderId: KnowledgeBaseFolderId,
    file: File,
    kbFile: KnowledgeBaseFileEntity,
    percentage: number,
  ) => void;
  onUploadComplete?: () => void;
};

export type IHandleFileUploadFactoryType = (
  factoryProps: IHandleFileUploaderFactoryProps,
) => (
  kbId: KnowledgeBaseId,
  folderId: KnowledgeBaseFolderId,
  files: ExternalFileWithPath[],
) => Promise<void>;

export type FileOrFolder = KnowledgeBaseFileEntity | KnowledgeBaseFolderEntity;

export type IHandleFileAndFolderDeleteFactoryProps = {
  onDeleteSubmission?: () => void;
  onDeleteBegin?: (kbId: KnowledgeBaseId, fileOrFolder: FileOrFolder) => void;
  onDeleteError?: (
    kbId: KnowledgeBaseId,
    fileOrFolder: FileOrFolder,
    err?: any,
  ) => void;
  onDeleteSuccess?: (kbId: KnowledgeBaseId, fileOrFolder: FileOrFolder) => void;
  onDeleteProgress?: (
    kbId: KnowledgeBaseId,
    fileOrFolder: FileOrFolder,
    percentage: number,
  ) => void;
  onDeleteComplete?: () => void;
};

export type IHandleFileAndFolderDeleteFactoryType = (
  factoryProps: IHandleFileAndFolderDeleteFactoryProps,
) => (
  files: KnowledgeBaseFileEntity[],
  folders: KnowledgeBaseFolderEntity[],
) => Promise<void>;

export interface IFileManagerContext {
  handleFileUploadFactory: IHandleFileUploadFactoryType;
  handleFileAndFolderDeleteFactory: IHandleFileAndFolderDeleteFactoryType;
  deleteTask: (taskId: string) => void;
  clearTasks: () => void;
  addTask: (taskId: string, task: IFileManagerTask) => void;
  tasks: IFileManagerTask[];
}

export interface FileUploadObject {
  file: FileWithPath;
  uploadResponse: CreateKnowledgeBaseFileUploadResponse;
}
