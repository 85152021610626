/* eslint-disable no-await-in-loop */

'use client';

import axios from 'axios';
import { Map } from 'immutable';
import path from 'path';
import { createContext, useCallback, useMemo, useState } from 'react';

import type { ExternalFileWithPath } from '@/api/types';
import {
  type KnowledgeBaseFileEntity,
  type KnowledgeBaseFolderEntity,
  KnowledgeBaseService,
} from '@/generated/clients/ludus-service-client/requests';
import { getAllFilesAndFolders } from '@/hooks/use-list-knowledge-base-folder';
import { batchPromiseAll } from '@/utils/async.utils';
import { extractMessageFromException } from '@/utils/exception.utils';

import type {
  FileUploadObject,
  IFileManagerContext,
  IFileManagerProviderProps,
  IFileManagerTask,
  IHandleFileAndFolderDeleteFactoryType,
  IHandleFileUploadFactoryType,
} from './file-manager.provider.interface';
import {
  DeleteFileTask,
  DeleteFolderTask,
  UploadFileTask,
} from './file-manager.provider.interface';

export const FileManagerContext = createContext<
  IFileManagerContext | undefined
>(undefined);

export const getFileNameFromPath = (filePath: string) => {
  return path.basename(filePath);
};

export const getFoldersFromPath = (filePath: string): string[] => {
  const hasFolders = filePath.split('/').length > 1;

  if (hasFolders) {
    const dir = path.dirname(filePath);
    const folders = dir
      .split('/')
      .map((name) => name.trim())
      .filter((name) => name !== undefined && name.length > 0);

    return folders;
  }
  return [];
};

export const FileManagerProvider = ({
  children,
}: IFileManagerProviderProps) => {
  const [taskMap, setTaskMap] = useState<Map<string, IFileManagerTask>>(Map());

  const mergeTaskMap = useCallback((newMap: Map<string, IFileManagerTask>) => {
    setTaskMap((prev) => {
      return prev.merge(newMap);
    });
  }, []);

  const deleteTask = useCallback((taskId: string) => {
    setTaskMap((prev) => {
      return prev.delete(taskId);
    });
  }, []);

  const clearTasks = useCallback(() => {
    setTaskMap(Map());
  }, []);

  const addTask = useCallback((taskId: string, task: IFileManagerTask) => {
    setTaskMap((prev) => {
      return prev.set(taskId, task);
    });
  }, []);

  const handleFileUploadFactory: IHandleFileUploadFactoryType = useCallback(
    ({
      onUploadBegin,
      onUploadError,
      onUploadSuccess,
      onUploadProgress,
      onUploadComplete,
    }) =>
      async (kbId, folderId, files) => {
        let taskMapBuffer: Map<string, IFileManagerTask> = Map();

        const upsertTask = (task: IFileManagerTask) => {
          taskMapBuffer = taskMapBuffer.set(task.id, task);
        };

        const interval = setInterval(() => {
          mergeTaskMap(taskMapBuffer);
        }, 2000);

        try {
          if (files && files.length > 0) {
            const now = new Date();

            for (const file of files) {
              const filePath =
                file.path || file.webkitRelativePath || file.name;

              const taskId = `upload_${kbId}_${folderId}_${filePath}`;

              const fileName = getFileNameFromPath(filePath);
              const folders = getFoldersFromPath(filePath);

              upsertTask(
                new UploadFileTask({
                  id: taskId,
                  progress: 0,
                  status: 'STARTED',
                  fileName,
                  fileSize: file.size,
                  folders,
                  startDate: now,
                }),
              );
            }

            mergeTaskMap(taskMapBuffer);

            const fileUploadObjects = (
              await batchPromiseAll<
                ExternalFileWithPath,
                FileUploadObject | undefined
              >({
                items: files,
                batchSize: 5,
                task: async (file) => {
                  const filePath =
                    file.path || file.webkitRelativePath || file.name;

                  const taskId = `upload_${kbId}_${folderId}_${filePath}`;

                  const fileName = getFileNameFromPath(filePath);
                  const folders = getFoldersFromPath(filePath);

                  try {
                    onUploadBegin?.(kbId, folderId, file);

                    const uploadResponse =
                      await KnowledgeBaseService.createKnowledgeBaseFileUpload({
                        kbId,
                        requestBody: {
                          fileName,
                          folders,
                          mimeType: file.type as any,
                          fileSizeInBytes: file.size,
                          parentFolderId: folderId,
                          externalUpload:
                            file.externalFileId && file.externalApplication
                              ? {
                                  externalFileId: file.externalFileId,
                                  application: file.externalApplication,
                                }
                              : undefined,
                        },
                      });

                    return {
                      file,
                      uploadResponse,
                    };
                  } catch (err: any) {
                    console.log('upload', JSON.stringify(err));
                    upsertTask(
                      new UploadFileTask({
                        id: taskId,
                        progress: 0,
                        status: 'FAILED',
                        fileName,
                        fileSize: file.size,
                        folders,
                        startDate: now,
                        err: extractMessageFromException(err),
                      }),
                    );

                    onUploadError?.(kbId, folderId, file, err);
                    return undefined;
                  }
                },
              })
            ).filter((v) => v) as FileUploadObject[];

            await batchPromiseAll<FileUploadObject>({
              items: fileUploadObjects,
              batchSize: 25,
              task: async (uploadObject) => {
                const { file, uploadResponse } = uploadObject;
                const { uploadDetails } = uploadResponse;
                const { presignedFields, presignedUrl } = uploadDetails;

                const filePath =
                  file.path || file.webkitRelativePath || file.name;

                const taskId = `upload_${kbId}_${folderId}_${filePath}`;

                const fileName = getFileNameFromPath(filePath);
                const folders = getFoldersFromPath(filePath);

                try {
                  const form = new FormData();
                  Object.entries<any>(presignedFields).forEach(
                    ([field, value]) => {
                      form.append(field, value);
                    },
                  );

                  form.append('file', file);

                  // eslint-disable-next-line no-await-in-loop
                  await axios.post(presignedUrl, form, {
                    onUploadProgress: (progressEvent) => {
                      const { loaded, total } = progressEvent;

                      if (total) {
                        const numerator = loaded;
                        const denominator = total;

                        const percentage = Math.floor(
                          (numerator / denominator) * 100,
                        );

                        console.log(percentage);

                        upsertTask(
                          new UploadFileTask({
                            id: taskId,
                            progress: percentage,
                            status: 'IN_PROGRESS',
                            fileName,
                            fileSize: file.size,
                            folders,
                            startDate: now,
                          }),
                        );

                        onUploadProgress?.(
                          kbId,
                          folderId,
                          file,
                          uploadResponse.file,
                          percentage,
                        );
                      }
                    },
                  });

                  upsertTask(
                    new UploadFileTask({
                      id: taskId,
                      progress: 100,
                      status: 'SUCCESS',
                      fileName,
                      fileSize: file.size,
                      folders,
                      startDate: now,
                    }),
                  );

                  onUploadSuccess?.(
                    kbId,
                    folderId,
                    uploadObject.file,
                    uploadObject.uploadResponse.file,
                  );
                } catch (err: any) {
                  upsertTask(
                    new UploadFileTask({
                      id: taskId,
                      progress: 0,
                      status: 'FAILED',
                      fileName,
                      fileSize: file.size,
                      folders,
                      startDate: now,
                      err: extractMessageFromException(err),
                    }),
                  );
                  onUploadError?.(kbId, folderId, uploadObject.file, err);
                }
              },
            });
          }
        } catch (err: any) {
          console.log('Error while attempting to upload', err);
        } finally {
          mergeTaskMap(taskMapBuffer);
          clearInterval(interval);
          onUploadComplete?.();
        }
      },
    [mergeTaskMap],
  );

  const handleFileAndFolderDeleteFactory: IHandleFileAndFolderDeleteFactoryType =
    useCallback(
      ({
        onDeleteSubmission,
        onDeleteBegin,
        onDeleteError,
        onDeleteSuccess,
        onDeleteProgress,
        onDeleteComplete,
      }) =>
        async (files, folders) => {
          let taskMapBuffer: Map<string, IFileManagerTask> = Map();

          const upsertTask = (task: IFileManagerTask) => {
            taskMapBuffer = taskMapBuffer.set(task.id, task);
          };

          const interval = setInterval(() => {
            mergeTaskMap(taskMapBuffer);
          }, 2000);

          try {
            const now = new Date();

            const nestedFilesAndFolders = await batchPromiseAll<
              KnowledgeBaseFolderEntity,
              {
                nestedFiles: KnowledgeBaseFileEntity[];
                nestedFolders: KnowledgeBaseFolderEntity[];
              }
            >({
              items: folders,
              batchSize: 5,
              task: async (folder) => {
                const filesAndFolders = await getAllFilesAndFolders(
                  folder.knowledgeBaseId,
                  folder.id,
                  1000,
                );

                return {
                  nestedFiles: filesAndFolders.files,
                  nestedFolders: filesAndFolders.folders,
                };
              },
            });

            const nestedFiles = nestedFilesAndFolders.flatMap(
              (n) => n.nestedFiles,
            );
            const nestedFolders = nestedFilesAndFolders.flatMap(
              (n) => n.nestedFolders,
            );

            const allFiles = [...files, ...nestedFiles];

            // folders we need to delete one by one since folders need to be empty before it can be deleted
            // so we deleted the most nest one first then move out

            // reverse list to delete most nested folders first
            const allFolders = [...folders, ...nestedFolders].reverse();

            console.log('Files to be deleted', allFiles.length);

            allFiles.forEach((file) => {
              const taskId = `delete_${file.id}`;

              upsertTask(
                new DeleteFileTask({
                  id: taskId,
                  fileId: file.id,
                  status: 'STARTED',
                  progress: 0,
                  fileName: file.name,
                  startDate: now,
                }),
              );
            });

            allFolders.forEach((folder) => {
              const taskId = `delete_${folder.id}`;

              upsertTask(
                new DeleteFolderTask({
                  id: taskId,
                  folderId: folder.id,
                  status: 'STARTED',
                  progress: 0,
                  folderName: folder.name,
                  startDate: now,
                }),
              );
            });

            onDeleteSubmission?.();
            mergeTaskMap(taskMapBuffer);

            await batchPromiseAll<KnowledgeBaseFileEntity>({
              items: allFiles,
              batchSize: 5,
              task: async (file) => {
                const taskId = `delete_${file.id}`;

                try {
                  onDeleteBegin?.(file.knowledgeBaseId, file);

                  upsertTask(
                    new DeleteFileTask({
                      id: taskId,
                      fileId: file.id,
                      status: 'IN_PROGRESS',
                      progress: 0,
                      fileName: file.name,
                      startDate: now,
                    }),
                  );

                  const deletedFile =
                    await KnowledgeBaseService.deleteKnowledgeBaseFile({
                      kbId: file.knowledgeBaseId,
                      fileId: file.id,
                    });

                  upsertTask(
                    new DeleteFileTask({
                      id: taskId,
                      fileId: file.id,
                      status: 'SUCCESS',
                      progress: 100,
                      fileName: file.name,
                      startDate: now,
                    }),
                  );

                  onDeleteProgress?.(
                    deletedFile.knowledgeBaseId,
                    deletedFile,
                    100,
                  );
                  onDeleteSuccess?.(deletedFile.knowledgeBaseId, deletedFile);
                } catch (err: any) {
                  upsertTask(
                    new DeleteFileTask({
                      id: taskId,
                      fileId: file.id,
                      status: 'FAILED',
                      progress: 0,
                      fileName: file.name,
                      startDate: now,
                      err: extractMessageFromException(err),
                    }),
                  );

                  onDeleteError?.(file.knowledgeBaseId, file, err);
                }
              },
            });

            console.log('Done deleting files');

            console.log('Folders to be deleted', allFolders.length);
            for (const folder of allFolders) {
              const taskId = `delete_${folder.id}`;

              try {
                onDeleteBegin?.(folder.knowledgeBaseId, folder);

                upsertTask(
                  new DeleteFolderTask({
                    id: taskId,
                    folderId: folder.id,
                    status: 'IN_PROGRESS',
                    progress: 0,
                    folderName: folder.name,
                    startDate: now,
                  }),
                );

                const deletedFolder =
                  await KnowledgeBaseService.deleteKnowledgeBaseFolder({
                    kbId: folder.knowledgeBaseId,
                    folderId: folder.id,
                  });

                upsertTask(
                  new DeleteFolderTask({
                    id: taskId,
                    folderId: folder.id,
                    status: 'SUCCESS',
                    progress: 100,
                    folderName: folder.name,
                    startDate: now,
                  }),
                );

                onDeleteProgress?.(
                  deletedFolder.knowledgeBaseId,
                  deletedFolder,
                  100,
                );
                onDeleteSuccess?.(deletedFolder.knowledgeBaseId, deletedFolder);
              } catch (err: any) {
                upsertTask(
                  new DeleteFolderTask({
                    id: taskId,
                    folderId: folder.id,
                    status: 'FAILED',
                    progress: 0,
                    folderName: folder.name,
                    startDate: now,
                    err: extractMessageFromException(err),
                  }),
                );
                onDeleteError?.(folder.knowledgeBaseId, folder, err);
              }
            }

            console.log('Done deleting folders');
          } catch (err: any) {
            console.log(`Error while attemping delete`, err);
          } finally {
            clearInterval(interval);
            mergeTaskMap(taskMapBuffer);
            onDeleteComplete?.();
          }
        },
      [mergeTaskMap],
    );

  return (
    <FileManagerContext.Provider
      value={useMemo(() => {
        return {
          handleFileUploadFactory,
          handleFileAndFolderDeleteFactory,
          deleteTask,
          clearTasks,
          addTask,
          tasks: taskMap.toIndexedSeq().toArray(),
        };
      }, [
        handleFileUploadFactory,
        handleFileAndFolderDeleteFactory,
        deleteTask,
        clearTasks,
        addTask,
        taskMap,
      ])}
    >
      {children}
    </FileManagerContext.Provider>
  );
};
