'use client';

import 'vanilla-cookieconsent/dist/cookieconsent.css';

import { GoogleAnalytics } from '@next/third-parties/google';
import React, { useState } from 'react';

import { CookieConsent } from './cookie-consent';

const GOOGLE_ANALYTICS_TAG = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TAG;

export const CookieConsentManager = React.memo(() => {
  const [analyticsConsent, setAnalyticsConsent] = useState(false);

  return (
    <div className="hidden">
      <CookieConsent
        onConsent={({ cookie }) => {
          cookie.categories.forEach((acceptedCategory) => {
            if (acceptedCategory === 'analytics') {
              setAnalyticsConsent(true);
            }
          });
        }}
      />
      {GOOGLE_ANALYTICS_TAG && analyticsConsent && (
        <GoogleAnalytics gaId={GOOGLE_ANALYTICS_TAG} />
      )}
    </div>
  );
});

CookieConsentManager.displayName = 'CookieConsentManager';
