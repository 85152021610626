export type OpaqueCursor = string;

export interface IPaginatedResponse<T> {
  data: T[];
  pageInfo: {
    hasNextPage: boolean;
    nextCursor?: OpaqueCursor;
  };
}

// eslint-disable-next-line func-names
export const pageThrough = async function* <T>(
  limit: number,
  fetch: (
    limit: number,
    nextCursor?: OpaqueCursor,
  ) => Promise<IPaginatedResponse<T>>,
) {
  let cursor: OpaqueCursor | undefined;
  do {
    // eslint-disable-next-line no-await-in-loop
    const response = await fetch(limit, cursor);

    if (response.pageInfo.hasNextPage) {
      cursor = response.pageInfo.nextCursor;
    }

    yield* response.data;
  } while (cursor);
};
